import { render, staticRenderFns } from "./merchantList.html?vue&type=template&id=48f10b78&scoped=true&"
import script from "./merchantList.js?vue&type=script&lang=js&"
export * from "./merchantList.js?vue&type=script&lang=js&"
import style0 from "./merchantList.scss?vue&type=style&index=0&id=48f10b78&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f10b78",
  null
  
)

export default component.exports