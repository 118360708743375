import merchantListService from "../../../../services/merchantListService";
import $renderDate from "./../../../../mixins/renderDate";

export default {
  name: 'merchant-list',
  components: {},
  props: [],
  mixins: [$renderDate],
  data() {
    return {
      merchantList: "",
      statuses: "",
      currentPage: 1,
      tableHeaders: [
        "صاحب کسب و کار",
        "نام کسب و کار",
        "حوزه کسب و کار",
        "نام کاربری",
        "وضعیت کاربر",
        "تاریخ ثبت نام",
        "لیست تراکنش ها",
        "مدیریت کاربر",
      ],
      name:null,
      businessName:null,
      userName:null,
    }
  },
  computed: {

  },
  mounted() {
    this.getStatusFunc();
    this.getMerchantList();
  },
  methods: {
    //get merchant list
    getMerchantList() {
      merchantListService
        .getMerchantList(
          null,
          null,
          null,
          0,
          localStorage.getItem("bitrahAdminAccessToken"),
          false
        )
        .then((response) => {
          this.merchantList = response.data;
        });
    },
    //Search merchant list
    searchMerchant() {
      merchantListService
        .getMerchantList(
          this.name,
          this.businessName,
          this.userName,
          0,
          localStorage.getItem("bitrahAdminAccessToken"),
          false
        )
        .then((response) => {
          this.merchantList = response.data;
        });
    },
    // get status
    getStatusFunc() {
      merchantListService.getstatus().then((response) => {
        this.statuses = response.data;
      });
    },
    // Paging
    getPaginationData(id) {
      merchantListService
        .getMerchantList(
          this.name,
          this.businessName,
          this.userName,
          id - 1,
          localStorage.getItem("bitrahAdminAccessToken"),
        )
        .then((response) => {
          this.merchantList = response.data;
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass:
              "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false,
          });
        });
    },
    // change status
    changeStatus(index, type, status, id) {
      merchantListService
        .changeStatus(
          type,
          status,
          id,
          localStorage.getItem("bitrahAdminAccessToken"),
        )
        .then((response) => {
          if (response.data) {
            this.getMerchantList();
            this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
              title: this.$i18n.t("toast.successTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "successFontColor rtl text-right font-weight-bold py-3",
              headerClass:
                "successBackgroundColor successFontColor  rtl text-right",
              solid: false,
            });
          } else {
            this.getMerchantList();
            this.$bvToast.toast(this.$i18n.t("toast.failedOperation"), {
              title:  this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass:
                "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false,
            });
          }
        });
    },
  }
}


