import authAxios from "./config/need-auth-axios";
import bitrahAxios from "./config/bitrah-axios";

class merchantListService {
    getMerchantList(
        name=null,
        businessName=null,
        userName=null,
        pageNumber,
        merchantId,
        isActivated,
    ) {
        return authAxios.post("/admin/merchant/all/rd", {
            name:name,
            businessName:businessName,
            username:userName,
            merchantId: merchantId,
            isActivated: isActivated,
            pageableDTO: {
                page: pageNumber,
                size: 10,
                direction: "DESC",
                // sortBy: "onlineEnabled",
                sortBy: [
                    "updateTime",
                    "creationTime"
                ]
            }
        });
    }
    getstatus() {
        return bitrahAxios.get("/manifest/board/merchant/status/rd");
    }
    changeStatus(
        type,
        status,
        id,
        merchantId,
    ) {
        return authAxios.post("/admin/merchant/change/wr", {
            type: type,
            status: status,
            id: id,
            merchantId: merchantId
        });

    }
    getSingleMerchantData(
        userMerchantId,
        adminMerchantId,

    ) {
        return authAxios.post("/admin/merchant/profile/rd/", {
            merchantId: adminMerchantId,
            merchant: {
                merchantId: userMerchantId
            },
            "lastDays": 30
        });
    }
    changeRole(
        isAdmin,
        userId,
        adminMerchantId,

    ) {
        return authAxios.post("/admin/employee/promote/cmd/", {
            merchantId: adminMerchantId,
            isAdmin: isAdmin,
            employee: {
                id: userId
            }
        });
    }

    removeIpAddress(
        id,
        merchantId,
    ) {
        return authAxios.post("/admin/merchant/remove/ip/cmd", {
            merchantId:merchantId,
            id: id,

        });
    }
}


export default new merchantListService();
